import React, { Component } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	NavItem,
	NavLink,
	Nav,
	TabPane,
	TabContent,
	ListGroup,
	ListGroupItem,
} from "reactstrap";

import { connect } from "react-redux";
import Loading from "../../components/Loading";
import * as commandService from "../../services/command";
import * as commandItemService from "../../services/commandItem";
import FabricShopPopup from "./../PriceFabrics/shop/shopPopup";
import ManufactureShopPopup from "./../PriceManufactures/shop/shopPopup";
import { Line } from "rc-progress";
import classnames from "classnames";
import WHTable from "../../components/WHTable";
import Moment from "react-moment";
import * as commandStatusService from "../../services/commandStatus";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import CONFIG from "../../constants/parameters";
import EuroFormat from "../../components/EuroFormat/EuroFormat";
import { getCommandStatus } from "../../reducers/params";

class DetailsPopup extends Component {
	state = {
		open: false,
		data: {},
		activeTab: "1",
		loaded: true,
	};

	load = id => {
		this.setState({ create: false, loaded: false });

		commandService.view(id).then(data => {
			this.setState({ data: data, loaded: true });
		});
	};

	handleOpen = (id = null) => {
		this.setState({ create: true, open: true });

		if (id) {
			this.load(id);
		}
	};

	handleClose = () => {
		this.setState({ open: false, data: {} });
	};

	validCommand = () => {
		let data = {
			command: this.state.data,
			status: CONFIG.COMMAND_STATUS_VALIDATE,
		};

		this.setState({ loaded: false });

		return commandStatusService
			.create(data)
			.then(() => {
				this.setState({ loaded: true });

				this.props.validCommand(this.state);

				toast.success("Enregistrement réussi");

				this.handleClose();
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	toggle = tab => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	edit = data => {
		if (data.product.fabric) {
			this.refs.fabricShopPopup.handleEdit(data.id);
		} else if (data.product.manufacture) {
			this.refs.manufactureShopPopup.handleEdit(data.id);
		}
	};

	remove = id => {
		if (
			window.confirm(
				"Etes vous sûre de vouloir supprimer cette élément ?",
			)
		) {
			commandItemService.remove(id).then(() => {
				toast.success("Le produit a été supprimé !!");

				this.load(this.state.data.id);
			});
		}
	};

	columns = () => {
		let columns = [];

		if (this.state.data.currentStatus === CONFIG.COMMAND_STATUS_CREATE) {
			columns.push({
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color={"info"}
							onClick={() => this.edit(row.original)}
						>
							<i className={"fa fa-edit"} />
						</Button>
						<Button
							color={"danger"}
							onClick={() => this.remove(row.value)}
						>
							<i className={"far fa-trash-alt"} />
						</Button>
					</div>
				),
			});
		}

		columns.push(
			{
				Header: "Article",
				accessor: "label",
			},
			{
				Header: "Quantité",
				accessor: "quantity",
			},
			{
				Header: "Référence interne ",
				accessor: "internRef",
			},
			{
				Header: "Nom client final ",
				accessor: "finalCustomer",
			},
			{
				Header: "Prix unitaire",
				accessor: "unitPrice",
				Cell: row => <EuroFormat value={row.value} />,
			},
			{
				Header: "Prix total",
				accessor: "totalPrice",
				Cell: row => <EuroFormat value={row.value} />,
			},
			{
				Header: "Nom",
				accessor: "lastName",
			},
			{
				Header: "Prénom",
				accessor: "firstName",
			},
			{
				Header: "Rue",
				accessor: "deliveryAddress.street",
			},
			{
				Header: "Complement",
				accessor: "deliveryAddress.complement",
			},
			{
				Header: "Code postal",
				accessor: "deliveryAddress.zipCode",
			},
			{
				Header: "ville",
				accessor: "deliveryAddress.city",
			},
			{
				Header: "Pays",
				accessor: "deliveryAddress.country",
			},
			{
				Header: "Téléphone",
				accessor: "deliveryAddress.phone",
			},
			{
				Header: "Statut",
				accessor: "statusName",
			},
		);

		return columns;
	};

	onSubmit = data => {
		this.setState({ loaded: false });

		return commandStatusService
			.create(data)
			.then(() => {
				toast.success("Enregistrement réussi");

				this.setState({ loaded: true });

				this.handleClose();

				this.props.validCommand(this.state);
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	newData = data => {
		let lst = this.state.data;

		lst.totalPrice = data.command.totalPrice;

		let index = lst.commandItem.findIndex(el => el.id == data.id);

		if (index > -1) {
			lst.commandItem[index] = data;
		}

		this.setState({ data: lst });
	};

	render() {
		const { className, commandStatus } = this.props;
		const { loaded, data, open } = this.state;
		const {
			statusName,
			currentStatus,
			activeTab,
			commandItem,
			status,
			totalPrice,
		} = data;

		return (
			<div>
				<ManufactureShopPopup
					newData={this.newData}
					ref={"manufactureShopPopup"}
				/>
				<FabricShopPopup
					newData={this.newData}
					ref={"fabricShopPopup"}
				/>
				<Modal
					size={"lg"}
					isOpen={open}
					className={className}
					fade={false}
				>
					<ModalHeader toggle={() => this.handleClose()}>
						Informations sur votre commande
					</ModalHeader>

					{!loaded && <Loading />}
					<ModalBody>
						<div>
							Avancement de votre commande : {statusName}
							<Line
								percent={
									(currentStatus * 100) /
									(commandStatus.length * 100)
								}
							/>
							<Nav tabs>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === "1",
										})}
										onClick={() => {
											this.toggle("1");
										}}
									>
										Détails
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === "2",
										})}
										onClick={() => {
											this.toggle("2");
										}}
									>
										Historique
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={activeTab}>
								<TabPane tabId="1">
									<WHTable
										data={commandItem}
										columns={this.columns()}
										defaultPageSize={10}
										filterable={true}
									/>
								</TabPane>
								<TabPane tabId="2">
									<ListGroup>
										<ListGroupItem color={"info"}>
											Suivi de votre commande
										</ListGroupItem>
										{status &&
											status.map((data, index) => {
												return (
													<ListGroupItem key={index}>
														{data.statusName}{" "}
														<span
															className={
																"pull-right"
															}
														>
															{" "}
															Le{" "}
															<Moment
																format="DD/MM/YYYY"
																date={data.date}
															/>
														</span>
													</ListGroupItem>
												);
											})}
									</ListGroup>
								</TabPane>
							</TabContent>
						</div>
					</ModalBody>

					<ModalFooter>
						<div className={"mr-auto"}>
							<h3>
								<b>
									Total de votre commande :{" "}
									<EuroFormat value={totalPrice} />
								</b>
							</h3>
						</div>
						{currentStatus === CONFIG.COMMAND_STATUS_CREATE && (
							<Button
								onClick={this.validCommand}
								className={"btn btn-green btn-padded"}
								>
								Valider votre commande
							</Button>
						)}
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	commandStatus: getCommandStatus(state),
});

export default connect(
	mapStateToProps,
	null,
	null,
	{ withRef: true },
)(DetailsPopup);

import React, { Component } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
	Col,
	FormGroup,
	FormText,
	Label,
	FormFeedback,
	Input,
	InputGroup,
	Row,
} from "reactstrap";
import PropTypes from "prop-types";
import "react-select/dist/react-select.css";
import MaskedFormControl from "react-bootstrap-maskedinput";
import "react-datepicker/dist/react-datepicker.css";
import ApiConfig from "../../../constants/ApiConfig";

class FieldTxt extends Component {
	toggleIcon = () => {
		window.open(
			`${ApiConfig.icons}`,
			"wh icones center",
			"location=no, menubar=no, status=no, scrollbars=no, menubar=no, width=800, height=600",
		);
	};
	render = () => {
		const {
			require,
			mask,
			input,
			label,
			type,
			help,
			bsSize,
			placeholder,
			className,
			id,
			prepend,
			append,
			style,
			disabled,
			labelStyle,
			labelSize,
			inputSize,
			meta: { touched, error },
		} = this.props;

		return (
			<FormGroup>
				<div>
					{label && <Label style={labelStyle}>{label}</Label>}{" "}
					{label && require && (
						<span className="text-danger font-weight-bold">*</span>
					)}
				</div>
				<div>
					<InputGroup>
						{prepend && (
							<div className="input-group-prepend">
								<span className="input-group-text">
									{prepend.icon && (
										<i className={prepend.icon} />
									)}
									{!prepend.icon && prepend}
								</span>
							</div>
						)}

						{mask && (
							<MaskedFormControl
								{...input}
								id={id}
								bsSize={bsSize}
								{...(touched && error
									? { invalid: false }
									: {})}
								mask={mask}
							/>
						)}
						{!mask && (
							<Input
								{...input}
								id={id}
								bsSize={bsSize}
								style={style}
								placeholder={placeholder}
								className={className}
								disabled={disabled}
								type={type}
								{...(touched && error ? { invalid: true } : {})}
							/>
						)}
						{append && (
							<div className="input-group-append">
								<span className="input-group-text">
									{append.icon && (
										<i className={append.icon} />
									)}
									{!append.icon && append}
								</span>
							</div>
						)}
						{type == "icon" && (
							<div
								className="input-group-append"
								onClick={() => this.toggleIcon()}
							>
								<span className="input-group-text">
									<i className="fa fa-hand-pointer" />
								</span>
							</div>
						)}

						{touched && error && (
							<FormFeedback className="help-block">
								{error}
							</FormFeedback>
						)}
						{help && (
							<FormText className="help-block">{help}</FormText>
						)}
					</InputGroup>
				</div>
			</FormGroup>
		);
	};
}

FieldTxt.defaultProps = {
	mask: null,
	require: false,
	input: {},
	label: null,
	type: null,
	help: null,
	bsSize: null,
	className: null,
	placeholder: null,
	id: null,
	disabled: false,
	meta: {
		touched: false,
		error: null,
	},
};

FieldTxt.propTypes = {
	mask: PropTypes.string,
	input: PropTypes.object,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	type: PropTypes.string,
	className: PropTypes.string,
	help: PropTypes.string,
	bsSize: PropTypes.string,
	placeholder: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	require: PropTypes.bool,
	meta: PropTypes.object,
};

export default FieldTxt;

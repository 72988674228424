import React, { Component } from "react";
import { Nav, NavItem, NavbarBrand, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from "reactstrap";
import { NavLink } from "react-router-dom";
import HeaderDropdownAccount from "./HeaderDropdownAccount";
import MediaLibrary from "./MediaLibrary";
import { connect } from "react-redux";
import { getUserRoles, getUser } from "../../Authentication/redux";
import * as sessionService from "../../../utils/session/index";
import { withRouter } from "react-router";


const logo = `url(img/logo.svg)`;

class MyFooter extends Component {

	constructor(props) {
		super(props);
		this.logout = this.logout.bind(this);
	}


	sidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-hidden");
	}

	mobileSidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-mobile-show");
	}

	logout() {
		sessionService.logout().then(res => {
			this.props.history.push("/login");
		});
	}

	render() {
		const { userRoles } = this.props;
		return (
			<header className="app-header navbar">

				<div className={'navbar-brand-group'}>
					<NavbarBrand href="#" style={{ backgroundImage: logo }} />
					<span className={'navbar-brand-name'}>
						<p><strong>MYDITEX</strong></p>
						<small>MY DIGITAL TEXTILE</small>
					</span>
				</div>

				<Nav navbar>
					{userRoles.includes("ROLE_ADMIN") && (
						<NavItem className="d-md-down-none">
							<NavLink
								to="/"
								className="nav-link"
								style={{ padding: "0 20px" }}
							>
								Tableau de bord
							</NavLink>
						</NavItem>
					)}
					{userRoles.includes("ROLE_ADMIN") && (
						<NavItem className="d-md-down-none">
							<NavLink
								to="/costumers"
								className="nav-link"
								style={{ padding: "0 20px" }}
							>
								Client
							</NavLink>
						</NavItem>
					)}
					{userRoles.includes("ROLE_ADMIN") && (
						<NavItem className="d-md-down-none">
							<NavLink
								to="/users"
								className="nav-link"
								style={{ padding: "0 20px" }}
							>
								Membres
							</NavLink>
						</NavItem>
					)}


					{userRoles.includes("ROLE_ADMIN") && (

						<UncontrolledDropdown nav inNavbar>
							<DropdownToggle nav caret>
								Catalogue
						</DropdownToggle>
							<DropdownMenu right>
								<DropdownItem>
									<NavItem className="d-md-down-none">
										<NavLink
											to="/products_manufactures"
											className="nav-link"
											style={{ padding: "0 20px" }}
										>
											Produits confectionnés
									</NavLink>
									</NavItem>
								</DropdownItem>
								<DropdownItem>
									<NavItem className="d-md-down-none">
										<NavLink
											to="/products_fabrics"
											className="nav-link"
											style={{ padding: "0 20px" }}
										>
											Tissus
									</NavLink>
									</NavItem>
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>

					)}



				</Nav>

				<Nav className="ml-auto accountBar" navbar>
					{/* <MediaLibrary /> */}
					<HeaderDropdownAccount />
					<div className="btn btn-gray logout-btn" onClick={() => this.logout()}>
						<img
							src={`img/logout-icon.svg`}
						/>
					</div>
				</Nav>
			</header>
		);
	}
}

const mapStateToProps = state => ({
	user: getUser(state),
	userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(withRouter(MyFooter));

import React, { Component, Fragment } from "react";
import WHTable from "../../components/WHTable";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {Button, Card, CardHeader, CardFooter} from "reactstrap";
import ImportPopup from "./ImportPopup";
import * as mediaService from "../../services/media";
import DetailsPopup from "./DetailsPopup";
import { getUserRoles, getUser } from "../Authentication/redux";
import matchSorter from 'match-sorter';

class Files extends Component {
	state = {
		data: [],
		pages: null,
		loaded: true,
		canDuplicate: true,
	};

	download = (id) => {
		mediaService.download(id);
	};

	columns = () => {
		return [
			{
				Header: "Nom",
				accessor: 'title',
				filterMethod: (filter, rows) =>
				  matchSorter(rows, filter.value, { keys: ["name"] }),
				  filterable: false,
				  Cell: row => (
					<strong>{row.value}</strong>
				),
			},
			{
				Header: "Format",
				accessor: "mimeType",
				filterable: false
			},
			{
				Header: "Taille",
				accessor: "size",
				filterable: false
			},
			{
				Header: "Poids",
				accessor: "weight",
				filterable: false
			},
			{
				Header: "Statut",
				accessor: "locked",
				maxWidth: 80,
				Cell: row => (
					<Fragment>
						{row.value && 
							(<i className="fa fa-lock"/>)
						}
						{!row.value &&
							(<i className="fa fa-unlock"/>)
						}
					</Fragment>
				),
				filterMethod: (filter, row) => {
				  if (filter.value === "all") {
					return true;
				  }
				  if (filter.value === "true") {
					return row[filter.id] === true;
				  }
				  return row[filter.id] === false;
				},
				
				Filter: ({ filter, onChange }) =>
				<select
				  onChange={event => onChange(event.target.value)}
				  style={{ width: "100%" }}
				  value={filter ? filter.value : "all"}
				>
				  <option value="all">Tout</option>
				  <option value="1">Vérouillé</option>
				  <option value="0">Déverouillé</option>
				</select>
			},
			{
				Header: "Actions",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color={"transparent"}
							onClick={() => this.download(row.value)}
						>
							<i className={"fa fa-download"} />
						</Button>
					</div>
				),
				minWidth: 100,
				maxWidth: 100,
			},

		];
	};

	remove = toDelete => {
		if (
			window.confirm(
				"Etes vous sûre de vouloir supprimer cette commande ?",
			)
		) {
			this.setState({ loaded: false });

			mediaService
				.remove(toDelete)
				.then(data => {
					toast.success("La commande a été supprimée!!");

					this.load(this.state);
				})
				.catch(exception => {
					this.setState({ loaded: true });

					toast.error(
						"Impossible de supprimer le produit, des dépences existent",
					);
				});
		}
	};
	load = (state, instance) => {
		this.setState({ loaded: false });

		return mediaService.search(state).then(data => {
			this.setState({ data: [] }, () => {
				this.setState({
					data: data["hydra:member"],
					pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
					loaded: true,
				});
			});
		});
	};

	newData = data => {
		let lst = this.state.data;

		let index = lst.findIndex(el => el.id == data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({ data: lst });
	};

	render() {
		const { data, pages } = this.state;
		const { user } = this.props;

		return (
			<div className="animated fadeIn">
				<ImportPopup load={this.load} newData={this.newData} ref={"importPopup"} />
				<DetailsPopup
					validCommand={this.validCommand}
					submit={this.onSubmit}
					ref={connectedComponent =>
						(this._detailsPopup = connectedComponent
							? connectedComponent.getWrappedInstance()
							: null)
					}
				/>

				<Card>
					<CardHeader>
						<h1>Mes fichiers</h1>
						<a
								href={`/help/#/configuration?id=gestion-des-membres`}
								target={"_blank"}
								className={"card-header-tooltip"}

							>
								?
							</a>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
				<CardFooter>
						<div className="card-actions">
							{user.organisation && (
								<button
									onClick={() =>
										this.refs.importPopup.handleOpen()
									}
									className={"btn btn-gray btn-padded"}
								>
									{" "}
									Importer
								</button>
							)}

						</div>
					</CardFooter>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: getUser(state),
	userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(Files);

const userReducer = (state = {}, { payload, type }) => {
    switch (type) {
        case 'SET_USER':
            return { ...payload };

		case 'SET_ORGANISATION':
			return {...state, organisation:payload}
        default:
            return state;
    }
};

export default userReducer;

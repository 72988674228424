import React, { Component } from "react";
import { Form, Card, CardHeader, CardBody } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { WhFieldTxt, WhFieldSelectAsync } from "../../components/Form/whFields";
import { ModalBody, ModalFooter } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import * as costumerService from "../../services/costumer";
import * as productService from "../../services/fabric";

class FormPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			data: {},
		};
		this.getOptions = this.getOptions.bind(this);
		this.getOptionsProduct = this.getOptionsProduct.bind(this);
		this.onSelectAnswerChange = this.onSelectAnswerChange.bind(this);
	}

	getOptions(input) {
		if (!input) {
			return Promise.resolve({ options: [] });
		}

		return costumerService
			.search({ filtered: [{ id: "currentName", value: input }] })
			.then(data => {
				let lst = data["hydra:member"].map((el, index) => {
					let res = el;
					res.value = el.id;
					res.label = el.name;
					return res;
				});

				return { options: lst };
			});
	}

	getOptionsProduct(input) {
		if (!input) {
			return Promise.resolve({ options: [] });
		}

		return productService
			.search({ filtered: [{ id: "fabric.name", value: input }] })
			.then(data => {
				let lstProduct = data["hydra:member"].map((el, index) => {
					let res = el;
					res.value = el.id;
					res.label = el.fabric.name;
					return res;
				});

				return { options: lstProduct };
			});
	}

	onSelectAnswerChange(value, name) {
		let data = this.state.data;

		data[name] = value;

		this.setState({
			data: data,
		});
	}

	render() {
		const { error, handleSubmit, submitting } = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<ModalBody>
					<Field
						label="Société : "
						name="client"
						component={WhFieldSelectAsync}
						value={this.state.data.organisation}
						change={this.onSelectAnswerChange}
						type="select"
						multi={false}
						loadOptions={this.getOptions}
					/>

					<Field
						label="Produit : "
						name="product"
						value={this.state.data.product}
						component={WhFieldSelectAsync}
						change={this.onSelectAnswerChange}
						type="select"
						multi={false}
						loadOptions={this.getOptionsProduct}
					/>

					<Card>
						<CardHeader>
							<i className={"fa fa-money"}> </i>Tarifs
						</CardHeader>
						<CardBody>
							<Field
								label="1-4 ML: "
								name="price1"
								component={WhFieldTxt}
								type="number"
								className="form-control"
								min={0}
								step={0.01}
							/>
							<Field
								label="5-9 ML: "
								name="price2"
								component={WhFieldTxt}
								type="number"
								className="form-control"
								min={0}
								step={0.01}
							/>
							<Field
								label="10-19 ML : "
								name="price3"
								component={WhFieldTxt}
								type="number"
								className="form-control"
								min={0}
								step={0.01}
							/>
							<Field
								label="20-49 ML : "
								name="price4"
								component={WhFieldTxt}
								type="number"
								className="form-control"
								min={0}
								step={0.01}
							/>
							<Field
								label="50-99 ML : "
								name="price5"
								component={WhFieldTxt}
								type="number"
								className="form-control"
								min={0}
								step={0.01}
							/>
							<Field
								label="100-299 ML: "
								name="price6"
								component={WhFieldTxt}
								type="number"
								className="form-control"
								min={0}
								step={0.01}
							/>

							<Field
								label="300-500 ML : "
								name="price7"
								component={WhFieldTxt}
								type="number"
								className="form-control"
								min={0}
								step={0.01}
							/>

							<Field
								label="500 et + ML : "
								name="price8"
								component={WhFieldTxt}
								type="number"
								className="form-control"
								min={0}
								step={0.01}
							/>
						</CardBody>
					</Card>
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-gray btn-padded btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

FormPopup = reduxForm({
	form: "FormPopup",
})(FormPopup);

export default FormPopup;

import React, { Component } from "react";
import ApiConfig from "../../constants/ApiConfig";
import WHTable from "../../components/WHTable";
import { toast } from "react-toastify";
import EditPopup from "./EditPopup";

import { Card, CardHeader, CardFooter, Button } from "reactstrap";

import * as productService from "../../services/fabric";

class Fabrics extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			col: [],
			pages: null,
			loaded: true,
		};

		this.load = this.load.bind(this);
		this.columns = this.columns.bind(this);
		this.remove = this.remove.bind(this);
		this.newData = this.newData.bind(this);
	}

	columns() {
		return [
			{
				Header: "Référence",
				accessor: "reference",
			},
			{
				Header: "Nom",
				accessor: "fabric.name",
			},
			{
				Header: "Description",
				accessor: "description",
			},
			{
				Header: "Dimension",
				accessor: "fabric.dimension",
			},
			{
				Header: "Actions",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color={"transparent"}
							onClick={() =>
								this.refs.editPopup.handleOpen(row.value)
							}
						>
							<i className={"fa fa-edit"} />
						</Button>
						<Button
							color={"transparent"}
							onClick={() => this.remove(row.value)}
						>
							<i className={"far fa-trash-alt"} />
						</Button>
					</div>
				),
				minWidth: 100,
				maxWidth: 100,
			},

		];
	}

	load(state, instance) {
		this.setState({ loaded: false });

		return productService.search(state).then(data => {
			this.setState({ data: [] }, () => {
				this.setState({
					data: data["hydra:member"],
					pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
					loaded: true,
				});
			});
		});
	}

	remove(toDelete) {
		if (window.confirm("Etes vous sûre de vouloir supprimer cet item ?")) {
			this.setState({ loaded: false });

			productService
				.remove(toDelete)
				.then(data => {
					toast.success("Le produit a été supprimé !!");

					this.load(this.state);
				})
				.catch(exception => {
					this.setState({ loaded: true });

					toast.error(
						"Impossible de supprimer le produit, des dépences existent",
					);
				});
		}
	}

	newData(data) {
		let lst = this.state.data;

		let index = lst.findIndex(el => el.id == data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({ data: lst });
	}

	render() {
		const { history, match } = this.props;
		const { data, pages, loading } = this.state;

		return (
			<div className="animated fadeIn">
				<EditPopup ref={"editPopup"} newData={this.newData} />

				<Card>
					<CardHeader>
						<h1>Tissus</h1>
						<a
							href={`/help/#/configuration?id=gestion-des-produits`}
							target={"_blank"}
							className={"card-header-tooltip"}

						>
							?
						</a>

					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
					<CardFooter>
						<div className="card-actions">
							<button
								onClick={() => this.refs.editPopup.handleOpen()}
								className={"btn btn-gray btn-padded"}
							>
								Ajouter
							</button>

						</div>
					</CardFooter>
				</Card>
			</div>
		);
	}
}

export default Fabrics;

import React, { Component } from "react";
import WHTable from "../../components/WHTable";
import EditCustomerPopup from "./EditPopup";
import { toast } from "react-toastify";
import ImportPopup from "./ImportPopup";

import { setOrganisation } from '../Authentication/redux/actions'
import { Card, CardHeader, CardFooter, Button } from "reactstrap";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as costumerService from "../../services/costumer";

class Costumers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			col: [],
			pages: null,
			loaded: true,
		};
	}

	newData = (data) => {
		let lst = this.state.data;

		let index = lst.findIndex(el => el.id == data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({ data: lst });
	}

	remove(toDelete) {
		if (window.confirm("Etes vous sûre de vouloir supprimer cet item ?")) {
			this.setState({ loaded: false });

			costumerService
				.remove(toDelete)
				.then(data => {
					toast.success("Le client a été supprimé !!");

					for (var i = this.state.data.length - 1; i >= 0; i--) {
						if (this.state.data[i].id === toDelete) {
							this.setState({
								data: this.state.data.filter(item => {
									return item.id !== toDelete;
								}),
							});
						}
					}

					this.setState({ loaded: true });
				})
				.catch(exception => {
					this.setState({ loaded: true });

					toast.error("Une erreur est survenue");
				});
		}
	}

	columns = () => {
		const { user } = this.props;

		return [
			{
				id: "ref",
				Header: "Références",
				accessor: "id",
				minWidth: 100,
				filterable: false,
				Cell: row => (
					<strong>{row.value}</strong>
				),
			},
			{
				Header: "Nom",
				accessor: "currentName",
				minWidth: 250,
				filterable: false,
			},
			{
				Header: "Adresse postale",
				accessor: "fullAdress",
				minWidth: 250,
				filterable: false,
			},
			{
				Header: "Téléphone",
				accessor: "phone",
				minWidth: 150,
				filterable: false,
			},
			{
				id: "action",
				Header: "Actions",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						{user.organisation &&
							user.organisation.id === row.value ? (
							<Button
								color={"transparent"}
								onClick={() => this.untakeCustomer(row.value)}
							>
								<i className={"fa fa-unlink"} />
							</Button>
						) : (
							<Button
								color={"transparent"}
								onClick={() => this.takeCustomer(row.value)}
							>
								<i className={"fa fa-link"} />
							</Button>
						)}
						<Button
							color={"transparent"}
							onClick={() =>
								this.refs.editPopup.handleOpen(row.value)
							}
						>
							<i className={"fa fa-edit"} />
						</Button>
						<Button
							color={"transparent"}
							onClick={() => this.remove(row.value)}
						>
							<i className={"far fa-trash-alt"} />
						</Button>
					</div>
				),
				minWidth: 180,
				maxWidth: 180,
			}

		];
	}

	untakeCustomer = async id => {
		this.setState({ loaded: false });

		try {
			const data = costumerService.untake(id);
			this.props.setOrganisation(data.organisation);
			toast.success("Le client a été dissocié");
		} finally {
			this.setState({ loaded: true });
		}
	};

	takeCustomer = async id => {
		this.setState({ loaded: false });
		try {
			costumerService.take(id).then((data) => {
				this.props.setOrganisation(data.organisation);
				toast.success("Vous avez la main sur le client");
			});
		} finally {
			this.setState({ loaded: true });
		}
	};

	load = (state, instance) => {
		this.setState({ loaded: false });

		console.log("state", state)

		return costumerService.search(state).then(data => {
			this.setState({
				data: data["hydra:member"],
				pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
				loaded: true,
			});
		});
	}

	render() {
		const { history, match } = this.props;
		const { data, pages, loading } = this.state;
		return (
			<div className="animated fadeIn">
				<EditCustomerPopup ref={"editPopup"} newData={this.newData} />
				<ImportPopup ref={"importPopup"} />
				<Card>
					<CardHeader>
						<h1>Clients</h1>
						<a
							href={`/help/#/configuration?id=gestion-des-clients`}
							target={"_blank"}
							className={"card-header-tooltip"}
						>
							?
							</a>

					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
					<CardFooter>
						<div className="card-actions">
							<button
								onClick={() => this.refs.editPopup.handleOpen()}
								className={"btn btn-gray btn-padded"}
							>
								Ajouter
							</button>
							<button
								onClick={() =>
									this.refs.importPopup.handleOpen()
								}
								className={"btn btn-green btn-padded"}
							>
								Importer
							</button>

						</div>
					</CardFooter>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({ setOrganisation }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Costumers);

import React from "react";
import { Field, reduxForm } from "redux-form";
import { WhFieldTxt } from "../../components/Form/whFields";
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Form,
	Alert,
} from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { required, validEmail } from "../../utils/validators";

class FormMyInformations extends React.Component {
	test = () => {};

	render() {
		const { error, handleSubmit, submitting } = this.props;

		return (
			<Card>
				<Form onSubmit={handleSubmit} className="form-horizontal">
					<CardHeader>
						<h1>Mon compte</h1>
					</CardHeader>

					<CardBody>
						<Field
							label="Email : "
							name="email"
							component={WhFieldTxt}
							type="text"
							validate={[required, validEmail]}
						/>
						<Field
							label="Prénom : "
							name="firstname"
							component={WhFieldTxt}
							type="text"
							validate={required}
						/>
						<Field
							label="Nom : "
							name="lastname"
							component={WhFieldTxt}
							type="text"
							validate={required}
						/>
						<Field
							label="Téléphone : "
							name="phone"
							component={WhFieldTxt}
							type="text"
							validate={required}
						/>

						{error && <Alert color="danger">{error}</Alert>}

						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: 30,
							}}
						>
							<LaddaButton
								className="btn btn-gray btn-padded btn-ladda"
								loading={submitting}
								data-style={EXPAND_LEFT}
								type="submit"
							>
								Enregistrer
							</LaddaButton>
						</div>
					</CardBody>
				</Form>
			</Card>
		);
	}
}

FormMyInformations = reduxForm({
	form: "FormMyInformations",
})(FormMyInformations);

export default FormMyInformations;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { Nav, NavItem, NavLink as RsNavLink } from "reactstrap";
import { badge, title, divider } from "./navComponents";
import nav from "./_nav";
import SidebarMinimizer from "../SidebarMinimizer";
import recursiveFilter from "../../../utils/recursiveFilter";
import { getUserRoles, getUser } from "../../Authentication/redux";
import { isNullOrUndefined } from "util";

class Sidebar extends Component {
	handleClick = e => {
		e.preventDefault();
		e.target.parentElement.classList.toggle("open");
	};

	activeRoute = (routeName, props) =>
		props.location.pathname.indexOf(routeName) > -1
			? "nav-item nav-dropdown open"
			: "nav-item nav-dropdown";

	hideMobile = () => {
		if (document.body.classList.contains("sidebar-mobile-show")) {
			document.body.classList.toggle("sidebar-mobile-show");
		}
	};

	// todo Sidebar nav secondLevel
	secondLevelActive = routeName =>
		this.props.location.pathname.indexOf(routeName) > -1
			? "nav nav-second-level collapse in"
			: "nav nav-second-level collapse";

	render() {
		const { user, roles } = this.props;

		// nav label with nav link
		const navLabel = (item, key) => {
			const classes = {
				item: classNames("hidden-cn", item.class),
				link: classNames("nav-label", item.class ? item.class : ""),
				icon: classNames(
					!item.icon ? "fa fa-circle" : item.icon,
					item.label.variant ? `text-${item.label.variant}` : "",
					item.label.class ? item.label.class : "",
				),
			};
			return navLink(item, key, classes);
		};

		// nav item with nav link
		const navItem = (item, key) => {
			const classes = {
				item: classNames(item.class),
				link: classNames(
					"nav-link",
					item.variant ? `nav-link-${item.variant}` : "",
				),
				icon: classNames(item.icon),
			};
			return navLink(item, key, classes);
		};

		// nav link
		const navLink = (item, key, classes) => {

			const url = item.url || "";

			return (
				<NavItem key={key} className={classes.item}>
					{isExternal(url) ? (
						<RsNavLink href={url} className={classes.link} active>
							<i className={classes.icon} />
							{item.name}
							{badge(item.badge)}
						</RsNavLink>
					) : (
						<NavLink
							to={url}
							className={classes.link}
							activeClassName="active"
							onClick={this.hideMobile}
						>
							{ classes.icon ? (
								<i className={classes.icon} />
							) : (
								<span></span>
							) }
							
							{item.name}
							{badge(item.badge)}
						</NavLink>
					)}
				</NavItem>
			);
		};

		// nav dropdown
		const navDropdown = (item, key) => {
			return (
				<li
					key={key}
					className={this.activeRoute(item.url, this.props)}
				>
					<a
						className="nav-link nav-dropdown-toggle"
						href="#"
						onClick={this.handleClick}
					>
						<i className={item.icon} />
						{item.name}
					</a>
					<ul className="nav-dropdown-items">
						{navList(item.children)}
					</ul>
				</li>
			);
		};

		// nav type
		const navType = (item, idx) =>
			item.title
				? title(item, idx)
				: item.divider
				? divider(item, idx)
				: item.label
				? navLabel(item, idx)
				: item.children
				? navDropdown(item, idx)
				: navItem(item, idx);

		// nav list
		const navList = items =>
			items.map((item, index) => navType(item, index));

		const isExternal = url => {
			const link = url ? url.substring(0, 4) : "";
			return link === "http";
		};

		if ((user.organisation && user.organisation.id) || (user && roles.includes("ROLE_ADMIN"))) {
			return (
				<div className="sidebar">

					<nav className="sidebar-nav">
						<Nav>{navList(recursiveFilter(nav.items))}</Nav>
					</nav>

					<SidebarMinimizer />
				</div>
			);
		} else {
			return <div />;
		}
	}
}

Sidebar.defaultProps = {};

Sidebar.propTypes = {
	roles: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
	roles: getUserRoles(state),
	user: getUser(state),
});

export default connect(mapStateToProps)(Sidebar);

/* eslint-disable import/no-unresolved */
import { SET_PARAMS } from "./constants";
import { getParams } from "../../services/parameter";

export const setParams = () => async dispatch => {
	const params = await getParams();

	if (params) {
		dispatch({
			type: SET_PARAMS,
			payload: params,
		});
	}
};

export default null;

import React, { Component } from "react";
import { Modal, ModalHeader, Button, ModalBody, ModalFooter } from "reactstrap";
import Loading from "../../components/Loading";
import Dropzone from "react-dropzone";

import configApi from "../../constants/ApiConfig";
import * as session from "../../utils/session";
import { toast } from "react-toastify";

class ImportPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			loaded: true,
			file: null,
			error: {display:false, text:""}
		};
	}

	handleOpen = (id = null) => {
		this.setState({ open: true });
	}

	handleClose = () => {
		this.setState({ open: false, file: null, error:{display:false, text:""} });
	}

	onSubmit = () => {
		let formData = new FormData();
		formData.append("file", this.state.file[0]);

		this.setState({ loaded: false });


		fetch(configApi.url + "/api/media/upload", {
			method: "POST",
			body: formData,
			headers: {
				Authorization: "Bearer " + session.getJwtToken(),
			},
		}).then( (response) => {
			if (response.ok) {
				toast.success("Fichier importé");
				this.setState({ loaded: true });
				this.handleClose();
			}
			else {
				response.json().then((p) => {
					const error = {display:true, texts:p.errors}
					this.setState({error, loaded:true})
				})
			}
		})
	}


	onDrop = (files) => {
		this.setState({
			file: files,
		});
		console.log(this.state);
	}

	render() {
		const { className } = this.props;
		const { loaded } = this.state;

		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				{!loaded && <Loading />}
				<ModalHeader toggle={this.handleClose}>
					Importer
				</ModalHeader>
				<ModalBody>
					<div className={"row"}>
						<div className="col-xs-6 offset-3">
							<Dropzone
								multiple={false}
								onDrop={this.onDrop}
							>
								{({
									isDragAccept,
									isDragReject,
									acceptedFiles,
									rejectedFiles,
								}) => {
									if (this.state.error.display)
										return this.state.error.texts.map((text) => text)
									if (isDragAccept) {
										return "This file is authorized";
									}
									if (isDragReject) {
										return "This file is not authorized";
									}
									return acceptedFiles.length ||
										rejectedFiles.length
										? `Accepted ${
												acceptedFiles.length
										  }, rejected ${
												rejectedFiles.length
										  } files`
										: "Try dropping some files.";
								}}
							</Dropzone>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<div className="row">
						<div className="col-lg-12">
							<Button onClick={this.onSubmit}
									className={"btn btn-green btn-padded"}
							>
								Valider
							</Button>
						</div>
					</div>
				</ModalFooter>
			</Modal>
		);
	}
}

export default ImportPopup;

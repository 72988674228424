import React, { Component } from "react";
import { Modal, ModalHeader, Button, ModalBody, ModalFooter } from "reactstrap";
import Loading from "../../components/Loading";
import Dropzone from "react-dropzone";

import configApi from "../../constants/ApiConfig";
import { toast } from "react-toastify";
import * as session from "../../utils/session";

class ImportPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			loaded: true,
			file: null,
		};

		this.handleClose = this.handleClose.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onDrop = this.onDrop.bind(this);
	}

	handleOpen(id = null) {
		this.setState({ open: true });
	}

	handleClose() {
		this.setState({ open: false, file: null });
	}

	onSubmit() {
		this.setState({ loaded: false });
		let formData = new FormData();
		formData.append("file", this.state.file[0]);

		fetch(configApi.url + "/api/price/import/manufactured", {
			method: "POST",
			body: formData,
			headers: {
				Authorization: "Bearer " + session.getJwtToken().access_token,
			},
		}).then(data => {
			toast.success(
				"Fichier importé.\n Un mail de rapport vous sera transmis par mail",
			);

			this.setState({ loaded: true });

			this.handleClose();
		});
	}

	onDrop(files) {
		this.setState({
			file: files,
		});
		console.log(this.state);
	}

	render() {
		const { className } = this.props;
		const { loaded } = this.state;

		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				{!loaded && <Loading />}
				<ModalHeader toggle={() => this.handleClose()}>
					Importer
				</ModalHeader>
				<ModalBody>
					<div className={"row"}>
						<div className="col-xs-6 offset-3">
							<Dropzone
								multiple={false}
								onDrop={this.onDrop.bind(this)}
							>
								{({
									isDragActive,
									isDragReject,
									acceptedFiles,
									rejectedFiles,
								}) => {
									if (isDragActive) {
										return "This file is authorized";
									}
									if (isDragReject) {
										return "This file is not authorized";
									}
									return acceptedFiles.length ||
										rejectedFiles.length
										? `Accepted ${
												acceptedFiles.length
										  }, rejected ${
												rejectedFiles.length
										  } files`
										: "Try dropping some files.";
								}}
							</Dropzone>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<div className="row">
						<div className="col-lg-12">
							<Button onClick={this.onSubmit}
									className={"btn btn-green btn-padded"}							
							>
								Valider
							</Button>
						</div>
					</div>
				</ModalFooter>
			</Modal>
		);
	}
}

export default ImportPopup;

import React, { Component } from "react";
import { Form } from "reactstrap";
import { Field, reduxForm, FieldArray } from "redux-form";
import { WhFieldTxt } from "../../components/Form/whFields";
import { ModalBody, ModalFooter } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import Select from "react-select";
import ConfectionForm from "./views/ConfectionForm";

class FormPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	render() {
		const AsyncComponent = Select.Async;

		const { handleSubmit, submitting } = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<ModalBody>
					<Field
						label="Nom : "
						name="manufacture.name"
						component={WhFieldTxt}
						type="text"
						className="form-control"
					/>

					<Field
						label="Référence : "
						name="reference"
						component={WhFieldTxt}
						type="text"
						className="form-control"
					/>

					<Field
						label="Description : "
						name="description"
						component={WhFieldTxt}
						type="text"
						className="form-control"
					/>
					<Field
						label="Dimension : "
						name="manufacture.dimension"
						component={WhFieldTxt}
						type="text"
						className="form-control"
					/>

					<FieldArray
						name="manufacture.confection"
						component={ConfectionForm}
					/>
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-gray btn-padded btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

FormPopup = reduxForm({
	form: "FormPopup",
})(FormPopup);

export default FormPopup;

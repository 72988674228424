export default {
	items: [
		{
			name: "Page d'accueil",
			icon: "fa fa-home",
			url: "/dashboard",
		},
		{
			name: "Le Catalogue",
			icon: "fa fa-list",
			children: [
				{
					name: "Produits confectionnés",
					url: "/manufactures",
				},
				{
					name: "Tissus",
					url: "/fabrics",
				},
			],
		},
		{
			name: "Commandes",
			icon: "fa fa-inbox",
			url: "/commands",
		},
		{
			name: "Mes fichiers",
			icon: "fa fa-file-image",
			url: "/files",
		},
	],
};

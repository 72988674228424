import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import UserForm from "./UserForm";
import * as userService from "../../services/user";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import Locals from "../../components/Locals";

class CreateEditPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			data: {},
			loaded: true,
		};
	}

	handleOpen = (id = null) => {
		this.setState({
			open: true,
		});

		if (id) {
			this.setState({
				loaded: false,
			});

			userService.view(id).then(data => {
				this.setState({
					data,
					loaded: true,
				});
			});
		}
	}

	handleClose = () => {
		this.setState({
			open: false,
			data: {},
		});
	}

	onSubmit = (data) => {
		const edit = !data.id ? userService.create : userService.update;

		if (data.plainPassword === null) {
			delete data.plainPassword;
		}

		return edit(data)
			.then(data => {
				this.props.newData(data);

				toast.success("Enregistrement réussi");

				this.handleClose();
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	}

	render() {
		const { className, roles } = this.props;
		const { loaded } = this.state;
		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				<ModalHeader toggle={this.handleClose}>
					Editer
					<Locals />
				</ModalHeader>

				{!loaded && <Loading />}

				{loaded && (
					<div>
						<UserForm
							onSubmit={this.onSubmit}
							initialValues={this.state.data}
							roles={roles.filter((role, idx) => idx < 2)}
						/>
					</div>
				)}
			</Modal>
		);
	}
}

export default CreateEditPopup;

import React, {Component} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import FormPopup from './FormPopup';
import * as productService from '../../services/fabric';
import { SubmissionError } from 'redux-form';
import {toast} from 'react-toastify';
import Loading from '../../components/Loading';

class EditUserPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false, 
            data : {}, 
            loaded : true
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    handleOpen(id = null) {

        this.setState({create : true,open: true});

        if(id) {
            
            this.setState({create : false,loaded : false});

            productService.view(id).then((data) => {

                this.setState({data : data, loaded : true});

            }); 


        }

    }

    handleClose() {

        this.setState({open: false, data : {}});

    }

    onSubmit(data) {

        const edit = (!data.id) ? productService.create : productService.update;

        return edit(data).then((data) => {

            this.props.newData(data);

            toast.success('Enregistrement réussi');

            this.handleClose();

            this.setState({data:this.state.data.push(data)});


        }).catch((error) => {

            throw new SubmissionError(error);

        });


    }


    render() {

        const {className} = this.props;
        const {loaded} = this.state;

        return (

            <Modal isOpen={this.state.open} className={className} fade={false}>
                
                <ModalHeader toggle={() => this.handleClose()}>{this.state.create ? ' Créer un nouveau tissu' : 'Editer un tissu'}</ModalHeader>
            
                {!loaded && <Loading />}
                {loaded && <FormPopup onSubmit={this.onSubmit} initialValues={this.state.data} />}


            </Modal>

        );


    }
}

export default EditUserPopup

import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import Header from "./Header/";
import Sidebar from "./Sidebar/";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import * as session from "../../utils/session";
import Dashboard from "../Dashboard/";
import DashboardCostumer from "../DashboardCostumer";
import { bindActionCreators } from "redux";
import { setUser, getUserRoles, getUser } from "../Authentication/redux";
import MyInformations from "../Account/MyInformations";
import MyPassword from "../Account/MyPassword";
import Users from "../Users";
import { setParams } from "../../reducers/params";
import Loading from "../../components/Loading";

import Costumers from "../Costumers";
import Manufactures from "../Manufactures";
import Fabrics from "../Fabrics";
import PriceFabric from "../PriceFabrics";
import PriceManufacture from "../PriceManufactures";
import Commands from "../Commands";
import Files from "../Files";
import jwtDecode from "jwt-decode";
import { isBefore } from "date-fns";

class Layout extends Component {
	state = { loaded: false };

	componentDidMount() {
		this.checkAuthentication();
	}

	checkAuthentication = async () => {
		const { setParams, setUser } = this.props;

		const user = session.getUserData();
		const token = session.getJwtToken();

		if (token && user) {
			const decodedToken = jwtDecode(token);
			const tokenExpiration = new Date(decodedToken.exp * 1000);
			const hasTokenExpired = isBefore(tokenExpiration, new Date());

			if (hasTokenExpired) {
				toast.info("Votre session a expiré, veuillez vous reconnecter");
				this.props.history.push("/login");
			} else {
				this.setState({ loaded: true });
				setUser(user);
			}
		} else {
			toast.info("Pour accéder à cette page, veuillez vous reconnecter");
			this.props.history.push("/login");
		}
	};

	render() {
		const { userRoles, user } = this.props;
		const { loaded } = this.state;

		if (!loaded) return <Loading />;
		return (
			<div className="app">
				<Header />
				<ToastContainer
					position="top-center"
					hideProgressBar
					autoClose={3000}
				/>
				<div className="app-body">
					<Sidebar {...this.props} />
					<main className="main">
						{/* <Breadcrumb /> */}
						<Container fluid>
							<Switch>
								{user && userRoles.includes("ROLE_ADMIN") && (
									<Route
										path="/dashboard"
										name="Dashboard"
										component={Dashboard}
									/>
								)}
								<Route
									path="/home"
									name="Home"
									component={DashboardCostumer}
								/>
								{user && userRoles.includes("ROLE_ADMIN") && (
									<Route
										path="/costumers"
										name="Costumers"
										component={Costumers}
									/>
								)}
								{user && userRoles.includes("ROLE_ADMIN") && (
									<Route
										path="/products_manufactures"
										name="Confectionné"
										component={Manufactures}
									/>
								)}
								{user && userRoles.includes("ROLE_ADMIN") && (
									<Route
										path="/products_fabrics"
										name="Tissus"
										component={Fabrics}
									/>
								)}
								<Route
									path="/manufactures"
									name="Produits manufacturés"
									component={PriceManufacture}
								/>
								<Route
									path="/fabrics"
									name="Tissus"
									component={PriceFabric}
								/>
								<Route
									path="/commands"
									name="Produits manufacturés"
									component={Commands}
								/>
								<Route
									path="/files"
									name="Produits manufacturés"
									component={Files}
								/>
								<Route
									path="/account/informations"
									name="Mon compte"
									component={MyInformations}
								/>

								<Route
									path="/account/password"
									name="Mon compte"
									component={MyPassword}
								/>
								{user && userRoles.includes("ROLE_ADMIN") && (
									<Route
										path="/users"
										name="Gestion des membres"
										component={Users}
									/>
								)}
								{user && userRoles.includes("ROLE_ADMIN") && (
									<Redirect from="/" to="/dashboard" />
								)}
								{!user ||
									(!userRoles.includes("ROLE_ADMIN") && (
										<Redirect from="/" to="/home" />
									))}
							</Switch>
						</Container>
					</main>
				</div>
				{/* 				<Footer />
				 */}{" "}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: getUser(state),
	userRoles: getUserRoles(state),
});

export const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ setUser, setParams }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

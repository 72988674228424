export const required = (value) => {
	return Boolean(value) ? undefined : "Ce champs est obligatoire";
};

export const validEmail = (value) =>
	/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
		? undefined
		: "Veuillez renseigner une adresse e-mail valide";

export const validPassword = (value) =>
	value && value.length >= 8
		? undefined
		: "Votre mot de passe doit faire au moins 8 caractères";

import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Indicator from "../Indicator";
import SubCommandsList from "../SubCommands/SubCommandsList";

class Dashboard extends Component {

	render() {
		return (
			<div className="animated fadeIn">
				<Row>
					<Col>
						<Indicator/>
						<h1>Tableau de bord</h1>
						<SubCommandsList/>
					</Col>
				</Row>
			</div>
		);
	}
}

export default Dashboard;

import APICONFIG from "../constants/ApiConfig";
import fetch from "../utils/fetch";

const endPoint = APICONFIG.CRM;

const endPoints = {
  subCommands: `${endPoint}/sub-commands`,
};

export const fetchSubCommands = (query) =>
  fetch(endPoints.subCommands, "get", {}, query);

import { login } from "../../services/iam";
import * as storage from "../storage";
import * as api from "./api";

export const user = {
	id: null,
	name: null,
	email: null,
	roles: null,
};

export const onLogin = async (username, password) => {
	const result = await login({
		strategy: "local",
		email: username,
		password,
	});

	setUserData(result.user);
	setJwtToken(result.accessToken);
};

export const setUserData = (user) => {
	return storage.save("user", user);
};

export const getUserData = () => {
	return storage.get("user");
};

export const deleteUserData = () => {
	return storage.destroy("user");
};

export const setJwtToken = (jwtToken) => {
	return storage.save("jwt_token", jwtToken);
};

export const deleteJwtToken = () => {
	return storage.destroy("jwt_token");
};

export const logout = () => {
	deleteJwtToken();
	deleteUserData();
	return Promise.resolve("disconnected");
};

export const getJwtToken = () => {
	return storage.get("jwt_token");
};

export const checkRole = (role) => {
	let res = false;

	user.roles.map((el) => {
		if (el === role) res = true;
	});

	return res;
};

export const isAuthenticated = () => {
	return api
		.me()
		.then((response) => {
			user.id = response.id;
			user.name = response.name;
			user.email = response.email;
			user.roles = response.roles;

			return Promise.resolve(response);
		})
		.catch(() => {
			return Promise.reject("disconnected");
		});
};

import React, {Component} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import UserFormPopup from './FormPopup';
import * as customerService from '../../services/costumer';
import { SubmissionError } from 'redux-form';
import {toast} from 'react-toastify';
import Loading from '../../components/Loading';

class EditCustomerPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false, 
            data : {}, 
            loaded : true
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    handleOpen(id = null) {

        this.setState({create : true,open: true});

        if(id) {
            
            this.setState({create : false,loaded : false});

            customerService.view(id).then((data) => {

                this.setState({data, loaded : true}); 

            }); 


        }

    }

    handleClose() {

        this.setState({open: false, data : {}});

    }


    onSubmit(data) {

        data.status = 1;
        const edit = (!data.id) ? customerService.create : customerService.update;

        return edit(data).then((data) => {

            this.props.newData(data);

            toast.success('Enregistrement réussi');

            this.handleClose();

            this.props.newData(data);

        }).catch((error) => {

            toast.error('Formulaire incorrect');

            throw new SubmissionError(error);

        });


    }


    render() {

        const {className} = this.props;
        const {loaded} = this.state;

        return (

            <Modal isOpen={this.state.open} className={className} fade={false}>
                
                <ModalHeader toggle={() => this.handleClose()}>{this.state.create ? ' Nouveau' : 'Editer'}</ModalHeader>
            
                {!loaded && <Loading />}
                {loaded && <UserFormPopup onSubmit={this.onSubmit} initialValues={this.state.data} />}


            </Modal>

        );


    }
}

export default EditCustomerPopup

import React, { useEffect, useState } from "react";
import { fetchSubCommands } from "../../services/crm";

const Indicator = () => {
    const [totalPrice, setTotalPrice] = useState();
    const [totalProdPrice, setTotalProdPrice] = useState();

    useEffect( () => {
		loadPrice();
	}, []);
    
    const loadPrice = async () => {
		const result = await fetchSubCommands();
        setTotalPrice(result.totalAmount)

        const query = {status:{
            $gte: 30,
            $lte: 200
            }}
		const result2 = await fetchSubCommands(query);
        setTotalProdPrice(result2.totalAmount)
    }
    
    return(
        <>
            <div className="row">
                <div className="divIndicator">
                    <div>Total commandes en cours</div>
                    <div><p className="fontEuro">€{totalProdPrice != null ? `   ${totalProdPrice}` : " Loading" }</p></div>
                </div>
                <div className="divIndicator">
                    <div>Total commandes</div>
                    <div><p className="fontEuro">€{totalPrice != null ? `   ${totalPrice}` : " Loading" }</p></div>
                </div>
            </div>
        </>
    )
}

export default Indicator;
import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import CreateEditPopup from "./CreateEditPopup";
import WHTable from "../../components/WHTable";
import { toast } from "react-toastify";
import { Card, CardHeader, CardFooter, CardBody, Button, Badge } from "reactstrap";
import * as userService from "../../services/user";
import { getRoles } from "../../reducers/params";

class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			col: [],
			pages: null,
			loaded: true,
		};

		this.load = this.load.bind(this);
		this.columns = this.columns.bind(this);
		this.remove = this.remove.bind(this);
		this.newData = this.newData.bind(this);
	}

	columns() {
		return [
			{
				Header: "Prénom",
				accessor: "firstName",
				minWidth: 80,
				maxWidth: 120,
			},
			{
				Header: "Nom",
				accessor: "lastName",
				minWidth: 80,
				maxWidth: 120,
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "Société",
				accessor: "organisation.name",
			},
			{
				Header: "Role(s)",
				accessor: "roles",
				Cell: (row) => <div>{this.formatRole(row.value).join(", ")}</div>,
			},
			{
				Header: "Status",
				accessor: "enabled",
				filterable: false,
				maxWidth: 100,
				Cell: (row) => (
					<div>
						{row.value && <Badge color="success">Active</Badge>}
						{!row.value && <Badge color="danger">Désactivé</Badge>}
					</div>
				),
			},
			{
				Header: "Actions",
				accessor: "id",
				filterable: false,
				maxWidth: 150,
				Cell: (row) => (
					<div>
						<Button
							color={"transparent"}
							onClick={() => this.sendNewPassword(row.value)}
						>
							<i className={"far fa-envelope"} />
						</Button>
						<Button
							color={"transparent"}
							onClick={() =>
								this.refs.createEditPopup.handleOpen(row.value)
							}
						>
							<i className={"fa fa-edit"} />
						</Button>
						<Button
							color={"transparent"}
							onClick={() => this.remove(row.value)}
						>
							<i className={"far fa-trash-alt"} />
						</Button>
					</div>
				),
			},

		];
	}

	load(state) {
		this.setState({
			loaded: false,
		});

		return userService.search(state).then((data) => {
			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize
						),
						loaded: true,
					});
				}
			);
		});
	}

	sendNewPassword(id) {
		if (
			window.confirm(
				"Êtes-vous sûr de vouloir envoyer un nouveau mot de passe ?"
			)
		) {
			this.setState({
				loaded: false,
			});

			userService
				.sendNewPassword(id)
				.then(() => {
					this.setState({
						loaded: true,
					});

					toast.success("Un noueau mot de passe a été envoyé");
				})
				.catch(() => {
					this.setState({
						loaded: true,
					});

					toast.error("Une erreur est survenue");
				});
		}
	}

	remove(toDelete) {
		if (window.confirm("Êtes-vous sûr de vouloir supprimer ce membre ?")) {
			this.setState({
				loaded: false,
			});

			userService
				.remove(toDelete)
				.then(() => {
					let lst = this.state.data;

					let index = lst.findIndex((el) => el.id === toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({
							data: lst,
							loaded: true,
						});
					}
				})
				.catch(() => {
					alert(
						"Il existe des dépendances sur membre, il ne peut donc être supprimé"
					);
				});
		}
	}

	newData(data) {
		let lst = this.state.data;

		let index = lst.findIndex((el) => el.id === data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({
			data: lst,
		});
	}


	formatRole(roles) {
		const rolesValues = [
			{
				value: "ROLE_USER",
				label: "User"
			},
			{
				value: "ROLE_ADMIN",
				label: "Admin"
			},
			{
				value: "ROLE_CUSTOMER",
				label: "Customer"
			},
		]

		return roles.map((r, index) => {
			let res = rolesValues.find(role => role.value == r);
			return res.label
		})
	}

	render() {
		const { data, pages } = this.state;
		const { roles } = this.props;

		const rolesValues = [
			{
				value: "ROLE_USER",
				label: "User"
			},
			{
				value: "ROLE_ADMIN",
				label: "Admin"
			},
			{
				value: "ROLE_CUSTOMER",
				label: "Customer"
			},
		]


		return (
			<div className="animated fadeIn">
				<CreateEditPopup
					ref={"createEditPopup"}
					newData={this.newData}
					roles={rolesValues}
				/>

				<Card>
					<CardHeader>
						<h1>Membres</h1>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
					<CardFooter>
						<div className="card-actions">
							<button
								onClick={() =>
									this.refs.createEditPopup.handleOpen()
								}
								className={"btn btn-gray btn-padded"}
							>
								Ajouter un membre
							</button>
						</div>
					</CardFooter>
				</Card>
			</div>
		);
	}
}

Users.defaultProps = {};

Users.propTypes = {
	roles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
	roles: getRoles(state),
});

export default connect(mapStateToProps)(Users);

import React, {Component} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import FormPopup from './FormPopup';
import * as priceListService from '../../services/priceFabric';
import { SubmissionError } from 'redux-form';
import {toast} from 'react-toastify';
import Loading from '../../components/Loading';

class EditUserPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false, 
            data : {}, 
            loaded : true
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    handleOpen(id = null) {

        this.setState({create:true,open: true});


        if(id) {
            
            this.setState({loaded : false,create: false});

            priceListService.view(id).then((data) => {

                data.product.value = data.product.id;
                data.product.label= data.product.fabric.name;
                data.client.value = data.client.id;
                data.client.label= data.client.currentName;
                this.setState({data : data, loaded : true});

            }); 


        }

    }

    handleClose() {

        this.setState({open: false, data : {}});

    }

    onSubmit(data) {

        if(data.price1) data.price1= parseFloat(data.price1);
        if(data.price2) data.price2= parseFloat(data.price2);
        if(data.price3) data.price3= parseFloat(data.price3);
        if(data.price4) data.price4= parseFloat(data.price4);
        if(data.price5) data.price5= parseFloat(data.price5);
        if(data.price6) data.price6= parseFloat(data.price6);
        if(data.price7) data.price7= parseFloat(data.price7);
        if(data.price8) data.price8= parseFloat(data.price8);

        const edit =  this.state.create ? priceListService.create : priceListService.update;

        return edit(data).then((data) => {

            this.props.newData(data);


            toast.success('Enregistrement réussi');

            this.handleClose();

            this.setState({data:this.state.data.push(data)});

        }).catch((error) => {

            throw new SubmissionError(error);

        });


    }


    render() {

        const {className} = this.props;
        const {loaded} = this.state;

        return (

            <Modal isOpen={this.state.open} className={className} fade={false}>
                
                <ModalHeader toggle={() => this.handleClose()}>{this.state.create ? ' Nouveau' : 'Editer'}</ModalHeader>
            
                {!loaded && <Loading />}
                {loaded && <FormPopup onSubmit={this.onSubmit} initialValues={this.state.data} />}


            </Modal>

        );


    }
}

export default EditUserPopup

import React, { Component } from "react";
import { withRouter } from "react-router";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Dropdown,
} from "reactstrap";
import * as sessionService from "../../../utils/session/index";
import jwtDecode from "jwt-decode";
import { connect } from "react-redux";
import { getUser } from "../../Authentication/redux";

class HeaderDropdownAccount extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.logout = this.logout.bind(this);

		this.state = {
			dropdownOpen: false,
		};
	}

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		});
	}

	logout() {
		sessionService.logout().then((res) => {
			this.props.history.push("/login");
		});
	}

	componentDidMount() {
		// const decoded = jwtDecode()
	}

	render() {
		const { user } = this.props;

		return (
			<Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
				<DropdownToggle nav>
					<span className="userName">{`${user.firstname} ${user.lastname}`}</span>
					<i className="fa fa-user" />
				</DropdownToggle>

				<DropdownMenu right>
					<DropdownItem header tag="div" className="text-center">
						<strong>Mon compte</strong>
					</DropdownItem>

					<DropdownItem
						onClick={() =>
							this.props.history.push("/account/informations")
						}
					>
						<i className="fa fa-user" />
						<span>Mes infos</span>
					</DropdownItem>

					<DropdownItem
						onClick={() =>
							this.props.history.push("/account/password")
						}
					>
						<i className="fa fa-lock" />
						<span>Changer de mot de passe</span>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
}

const mapStateToProps = (state) => ({
	user: getUser(state),
});

export default connect(mapStateToProps)(withRouter(HeaderDropdownAccount));

import APICONFIG from "../constants/ApiConfig";
import fetch from "../utils/fetch";

const endPoint = APICONFIG.IAM;

console.log({ APICONFIG });

const endPoints = {
  authentication: `${endPoint}/authentication`,
  myAccount: `${endPoint}/my-account`,
  updatePassword: `${endPoint}/update-password`,
};

export const login = (data) => fetch(endPoints.authentication, "post", data);
export const fetchMyAccountInformations = () =>
  fetch(endPoints.myAccount, "get");
export const updateMyAccountInformations = (data) =>
  fetch(endPoints.myAccount, "post", data);

export const updatePassword = (password, newPassword) =>
  fetch(endPoints.updatePassword, "post", { password, newPassword });

import React, { Component } from "react";
import WHTable from "../../components/WHTable";
import EditDiscountPopup from "./EditPopup";
import ImportDiscountPopup from "./ImportPopup";
import ShopPopup from "./shop/shopPopup";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardHeader, CardFooter, Button } from "reactstrap";
import { getUserRoles, getUser } from "../Authentication/redux";
import * as tissuesService from "../../services/priceFabric";
import EuroFormat from "../../components/EuroFormat/EuroFormat";

class Prices extends Component {
	state = {
		data: [],
		col: [],
		pages: null,
		loaded: true,
	};

	columns = () => {
		const { userRoles } = this.props;

		const isAdmin = userRoles.includes("ROLE_ADMIN");

		let defaultlst = [];


		{
			isAdmin &&
				defaultlst.push({
					Header: "Client",
					accessor: "client.currentName",
					minWidth: 200,
				});
		}

		defaultlst.push(
			{
				Header: "Produit",
				accessor: "product.fabric.name",
			},
			{
				Header: "1-4 ML",
				accessor: "price1",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "5-9 ML",
				accessor: "price2",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "10-19 ML",
				accessor: "price3",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "20-49 ML",
				accessor: "price4",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "50-99 ML",
				accessor: "price5",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "100-299 ML",
				accessor: "price6",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "300-499 ML",
				accessor: "price7",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				Header: "500 et + ML",
				accessor: "price8",
				Cell: row => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
		);
		defaultlst.push({
			accessor: "id",
			filterable: false,
			Header: "",
			maxWidth: 100,
			Cell: row => (
				<div>
					<Button
						color={"transparent"}
						onClick={() =>
							this.refs.shopPopup.handleOpen(row.original)
						}
					>
							<i className="fas fa-shopping-cart"></i>
					</Button>
					{isAdmin && (
						<Button
							color={"transparent"}
							onClick={() =>
								this.refs.editPopup.handleOpen(row.value)
							}
						>
							<i className={"fa fa-edit"} />
						</Button>
					)}
					{isAdmin && (
						<Button
							color={"transparent"}
							onClick={() => this.remove(row.value)}
						>
							<i className={"far fa-trash-alt"} />
						</Button>
					)}
				</div>
			),
		});

		return defaultlst;
	};

	load = (state, instance) => {
		this.setState({ loaded: false });

		return tissuesService.search(state).then(data => {
			this.setState({ data: [] }, () => {
				this.setState({
					data: data["hydra:member"],
					pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
					loaded: true,
				});
			});
		});
	};

	remove = toDelete => {
		if (window.confirm("Etes vous sûre de vouloir supprimer cet item ?")) {
			this.setState({ loaded: false });

			tissuesService
				.remove(toDelete)
				.then(data => {
					toast.success("Traifs supprimés !!");

					this.load(this.state);
				})
				.catch(exception => {
					this.setState({ loaded: true });

					toast.error("Une erreur est survenue");
				});
		}
	};

	newData = data => {
		let lst = this.state.data;

		let index = lst.findIndex(el => el.id == data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({ data: lst });
	};

	render() {
		const { userRoles } = this.props;
		const { data, pages } = this.state;
		const isAdmin = userRoles.includes("ROLE_ADMIN");

		return (
			<div className="animated fadeIn">
				<EditDiscountPopup ref={"editPopup"} newData={this.newData} />
				<ImportDiscountPopup ref={"importPopup"} />
				<ShopPopup ref={"shopPopup"} />
				<Card>
					<CardHeader>
						<h1>Tissus</h1>
						<a
							href={`/help/#/catalogue?id=liste-des-tissus`}
							target={"_blank"}
							className={"card-header-tooltip"}
						>
							?
						</a>

					</CardHeader>
					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
					<CardFooter>
						<div className="card-actions">
							{isAdmin ? (
								<button
									onClick={() =>
										this.refs.importPopup.handleOpen()
									}
									className={"btn btn-gray btn-padded"}
								>
									{" "}
									Importer
								</button>
							) : (
								""
							)}
							{isAdmin ? (
								<button
									onClick={() =>
										this.refs.editPopup.handleOpen()
									}
									className={"btn btn-green btn-padded"}
								>
									Ajouter
								</button>
							) : (
								""
							)}


						</div>
					</CardFooter>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: getUser(state),
	userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(Prices);

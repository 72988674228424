import React, { Component } from "react";
import { Form, Col, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { WhFieldTxt } from "../../components/Form/whFields";
import { ModalBody, ModalFooter } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";

class UserFormPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	render() {
		const { error, handleSubmit, pristine, reset, submitting } = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<ModalBody>
					<Row>
						<Col xs="12" sm="6">
							<Field
								label="Société : "
								name="currentName"
								component={WhFieldTxt}
								type="text"
							/>
						</Col>
						<Col xs="12" sm="6">
							<Field
								label="Raison sociale : "
								name="socialReason"
								component={WhFieldTxt}
								type="text"
							/>
						</Col>
					</Row>
					<Row>
						<Col xs="12" sm="4">
							<Field
								label="Téléphone : "
								name="phone"
								component={WhFieldTxt}
								type="text"
							/>
						</Col>
						<Col xs="12" sm="8">
							<Field
								label="Numéro siret : "
								name="siretNumber"
								component={WhFieldTxt}
								type="text"
							/>
						</Col>
					</Row>
					<Row>
						<Col xs="3">
							<Field
								label="N°: "
								name="numVoie"
								component={WhFieldTxt}
								type="text"
							/>{" "}
						</Col>
						<Col xs="9">
							<Field
								label="Rue : "
								name="voie"
								component={WhFieldTxt}
								type="text"
							/>
						</Col>
					</Row>
					<Row>
						<Col xs="12">
							<Field
								label="Complement : "
								name="cplt"
								component={WhFieldTxt}
								type="text"
							/>
						</Col>
					</Row>
					<Row>
						<Col xs="3">
							<Field
								label="Code postal : "
								name="zipCode"
								component={WhFieldTxt}
								type="text"
							/>
						</Col>
						<Col xs="5">
							<Field
								label="Ville : "
								name="town"
								component={WhFieldTxt}
								type="text"
							/>
							{/* <Field
								label="Region : "
								name="region"
								component={WhFieldTxt}
								type="text"
							/> */}
						</Col>
						<Col xs="4">
							<Field
								label="Pays : "
								name="country"
								component={WhFieldTxt}
								type="text"
							/>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-gray btn-padded btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

UserFormPopup = reduxForm({
	form: "UserFormPopup",
})(UserFormPopup);

export default UserFormPopup;

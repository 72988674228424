import enhanceUserRoles from '../../../utils/enhanceUserRoles';

export const setUser = user => ({
    type: 'SET_USER',
    payload: enhanceUserRoles(user),
});

export const setOrganisation = organisation => {
	return ({
	type: 'SET_ORGANISATION',
	payload: organisation
})}
export default null;

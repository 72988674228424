import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import {
	WhFieldTxt,
	WhFieldSelectAsync,
} from "../../../components/Form/whFields";
import { Button, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import * as productService from "../../../services/fabric/index";

class ConfectionForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
		};

		this.getOptions = this.getOptions.bind(this);
		this.onSelectAnswerChange = this.onSelectAnswerChange.bind(this);
	}

	getOptions(input) {
		if (!input) {
			return Promise.resolve({ options: [] });
		}

		return productService
			.fabric({ filtered: [{ id: "name", value: input }] })
			.then((data) => {
				let lst = data["hydra:member"].map((el, index) => {
					let res = el;
					res.value = el.id;
					res.label = el.name;
					return res;
				});

				return { options: lst };
			});
	}

	onSelectAnswerChange(value, name) {
		let data = this.state.data;

		data[name] = value;

		this.setState({
			data: data,
		});
	}

	render() {
		const {
			fields,
			meta: { touched, error },
		} = this.props;

		return (
			<div>
				{fields.map((el, index) => {
					return (
						<div key={index}>
							<Card>
								<CardHeader>
									Confection
									<div className="card-actions">
										<Button
											className={"pull-right"}
											color={"danger"}
											onClick={() => fields.remove(index)}
										>
											<i className={"fa fa-times"}> </i>{" "}
										</Button>
									</div>
								</CardHeader>
								<CardBody>
									<div className="form-vertical">
										<Row>
											<Col md={6}>
												<Field
													label="Tissu : "
													name={`${el}.fabric`}
													component={
														WhFieldSelectAsync
													}
													change={
														this
															.onSelectAnswerChange
													}
													type="select"
													multi={false}
													loadOptions={
														this.getOptions
													}
												/>
											</Col>
											<Col md={6}>
												<Field
													label="Quantité : "
													name={`${el}.quantity`}
													component={WhFieldTxt}
													type="number"
													className="form-control"
													min={0}
													step={0.01}
												/>
											</Col>
										</Row>
									</div>
								</CardBody>
							</Card>
						</div>
					);
				})}
				<Button block color={"info"} onClick={() => fields.push({})}>
					Ajouter un tissu
				</Button>
			</div>
		);
	}
}

export default ConfectionForm;

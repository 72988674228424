import React, { Component } from "react";
import { Container, Row, Col, CardGroup, Card, CardBody } from "reactstrap";
import { SubmissionError } from "redux-form";
import FormLogin from "./FormLogin";
import { connect } from "react-redux";
import * as session from "../../../utils/session";
import { getUserRoles, getUser } from "../redux";
import { ToastContainer } from "react-toastify";

class Login extends Component {
	submit = async (values) => {
		const { userRoles } = this.props;
		try {
			await session.onLogin(values.username, values.password);
			this.props.history.push("/dashboard");
			/*if (userRoles.includes("ROLE_ADMIN")) {
				this.props.history.push("/dashboard");
			} else {
				this.props.history.push("/home");
			}*/
		} catch (error) {
			throw new SubmissionError({
				_error: error && error.message ? error.message : error,
			});
		}
	};

	render() {
		return (
			<div className="app flex-row align-items-center loginContainer">
				<ToastContainer
					position="top-center"
					hideProgressBar
					autoClose={3000}
				/>
				<Container>
					<Row className="justify-content-center">
						<Col xs="12" className="justify-content-center">
							<div className={"loginHeader"}>
								<img
									src="img/logo.svg"
									alt=""
									style={{ maxWidth: "100%" }}
								/>

								<h1>Connexion</h1>

								<p className="text-muted">
									Merci de vous identifier
								</p>
							</div>

							<div className="loginBody">
								<FormLogin onSubmit={this.submit} />
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: getUser(state),
	userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(Login);

import React, { useMemo, useEffect, useState } from "react";
import WHTable from "../../../components/WHTable";
import { fetchSubCommands } from "../../../services/crm";
import { Card, CardHeader, CardFooter, Button } from "reactstrap";
import Moment from "react-moment";
import EuroFormat from "../../../components/EuroFormat/EuroFormat";


const SubCommandsList = () => {

	const [data, setData] = useState([]);
	const [totalItemsCount, setTotalItemsCount] = useState();
	const [itemsCountPerPage, setItemsCountPerPage] = useState();
	const [filter, setFilter] = useState({});
	//const [pageSize, setPageSize] = useState(10);
	//const [page, setPage] = useState(0);
	//const [tableState, setTableState] = useState({});

	useEffect( () => {
		loadData();
	}, [filter]);

	const loadData = async (tableState) => {
		
		const pageSize = tableState != undefined ? tableState.pageSize : 10;
		const page = tableState != undefined ? tableState.page : 0;
		const query = {$skip:page, $limit:pageSize , ...filter};
		const result = await fetchSubCommands(query);
		setData(result.data);
		setTotalItemsCount(result.total);
		setItemsCountPerPage(pageSize); 
	};

  	const tagParameters = [
		{
			label: 'Prise de besoin',
			name: 'prise_de_besoin',
			query:{
				status:{
				$lt: 100
				}
			}
		},
		{
			label: 'Expédition',
			name: 'expedition',
			query:{
				status:{
				$gte: 300 
				}
			}
		},
		{
			label: 'Production',
			name: 'production',
			query: {
				status:{
				$gte: 100,
				$lt: 200
				}
			}
		}
	];

  	const [selectedTag, setSelectedTag] = useState(null);

	const columns = useMemo(
		() => [
			{
				id: "productRef",
				accessor: "productRef",
				Header: "Réference",
			},
			{
				id: "status",
				accessor: "statusName",
				Header: "Statut",
				Cell: (row) => (
					<span
						className={`label status-${row.original.statusName}`}
					>
						{row.value}
					</span>
				),
			},
			{
				id: "date",
				Header: "Expedition",
				accessor: "date",
				Cell: (row) => (
					<Moment date={row.value} format={"DD/MM/YYYY"} />
				),
			},
			{
				id: "information",
				accessor: "information",
				Header: "Informations",
			},
			{
				id: "metrage",
				accessor: "metrage",
				Header: "Métrage",
			},
			{
				id: "unitPrice",
				Header: "Prix",
				accessor: "unitPrice",
				Cell: (row) => (
					<div className={"text-center"}>
						<EuroFormat value={row.value} />
					</div>
				),
			},
			{
				id: "action",
				accessor: "action",
				Header: "Actions",
				Cell: (row) => (
					<div className={"text-center"}>
						<Button
							color={"transparent"}
							onClick={() => this.edit(row.value)}
						>
							<i className={"fa fa-edit"} />
						</Button>
						<Button
							color={"transparent"}
							onClick={() => this.remove(row.value)}
						>
							<i className={"far fa-trash-alt"} />
						</Button>
					</div>
				),
			},
		],
		[]
	);

	const numberOfPages = useMemo(() => {
		return Math.ceil(
		  itemsCountPerPage >= totalItemsCount
			? 1
			: (totalItemsCount + (itemsCountPerPage - (totalItemsCount % itemsCountPerPage))) / itemsCountPerPage
		)
	  }, [totalItemsCount, itemsCountPerPage])


	return (
		<>
    <div className={"row"}>
      <div className="dashMarg" >Sélectionnez un tag : </div>
      {tagParameters.map((param, index) => {
		
		const isChecked = selectedTag === param.name
		
        const handleClick= async () => {
			if(isChecked === false){
				setFilter(param.query);
				setSelectedTag(param.name);
			}else{
				setFilter({});
				setSelectedTag(null)
			}
        }

        return(<Button className="dashMarg" color={isChecked? "green" : "gray-600"} key={index} onClick={handleClick}>{param.label}</Button>)
      })}

    </div>
			<Card>
				<WHTable
					data={data}
					columns={columns}
					onFetchData={loadData}
					pages={numberOfPages}
					pageSize={itemsCountPerPage}
				/>
			</Card>
		</>
	);
};

export default SubCommandsList;

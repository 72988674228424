import React, { Component } from "react";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import * as customerService from '../../services/costumer';

import {
	WhFieldTxt,
	WhFieldSelect2,
	WhFieldSelectAsync,
	WhFile,
	WhFieldTranslate,
} from "../../components/Form/whFields";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";

class UserForm extends Component {
	test = () => {};

	getOptions = (input) => {
		if (!input) {
			return Promise.resolve({ options: [] });
		}

		return customerService.search({filtered : [{id: 'currentName', value : input}]})
			.then((data) => {
				console.log(data);
				let lst =  data['hydra:member'].map((el, index) => {
					let res = el;
					res.value = el.id;
					res.label = el.currentName;
					return res;
				});

				console.log(lst);
				return { options: lst };
			})

	};


	render() {
		const { error, handleSubmit, submitting, roles } = this.props;
		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<ModalBody>
					<Field
						label="Prénom :"
						name="firstName"
						component={WhFieldTxt}
						type="text"
						require="true"
					/>
					<Field
						label="Nom :"
						name="lastName"
						component={WhFieldTxt}
						type="text"
						require="true"
					/>
					<Field
						label="Téléphone :"
						name="phone"
						component={WhFieldTxt}
						type="text"
						require="true"
					/>
					<Field
						label="Email :"
						name="email"
						component={WhFieldTxt}
						type="text"
						require="true"
					/>

					<Field
						label="Rôles :"
						name="roles"
						component={WhFieldSelect2}
						type="select"
						options={roles}
					/>

					<Field
						label="Société : "
						name="organisation"
						component={WhFieldSelectAsync}
						multi={false}
						type="select"
						loadOptions={this.getOptions}
					/>

					{error && (
						<Alert color="danger">
							<div dangerouslySetInnerHTML={{ __html: error }} />
						</Alert>
					)}
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-gray btn-padded btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type="submit"
					>
						Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

const formName = "FormUser";

const rxf = reduxForm({ form: formName })(UserForm);

UserForm.defaultProps = {
	error: "",
	currentValues: {},
};
UserForm.propTypes = {
	error: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	currentValues: PropTypes.shape({}),
};

export const mapStateToProps = state => ({});

export default connect(mapStateToProps)(rxf);
